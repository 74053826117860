import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
import { MDXProvider } from '@mdx-js/react';
import BaseLayout from '../components/templates/BaseLayout';
import PrimaryHeader from '../components/atoms/typography/PrimaryHeader';
import SEO from '../components/atoms/utility/seo';
import DocsSidebar from '../components/organisms/DocsSidebar';
import { getAnchor } from '../utils/helpers/strings';

deckDeckGoHighlightElement();

export default function DocsTemplate({ data, pageContext }) {
    const {
        mdx: {
            frontmatter: { title, description },
            body,
        },
    } = data;

    const blogHeader1 = (props) => (
        <h1
            className="mt-6 mb-4 font-extrabold text-3xl md:text-4xl lg:text-5xl"
            {...props}
        />
    );

    const blogHeader2 = ({ children }) => {
        const anchor = getAnchor(children);
        const link = `#${anchor}`;
        return (
            <h2
                id={anchor}
                className="mt-6 mb-4 font-extrabold text-2xl md:text-3xl lg:text-4xl relative group"
            >
                <a
                    href={link}
                    className="absolute opacity-0 -translate-x-8 w-2 group-hover:opacity-100 transition-all"
                >
                    §
                </a>
                {children}
            </h2>
        );
    };

    const blogHeader3 = (props) => (
        <h3 className="mt-5 mb-3 text-2xl md:text-2xl lg:text-3xl" {...props} />
    );

    const blogHeader4 = (props) => (
        <h4 className="mt-4 mb-2 text-lg md:text-xl lg:text-2xl" {...props} />
    );

    const blogHeader5 = (props) => (
        <h5 className="mt-3 mb-2 text-xl" {...props} />
    );

    const blogHeader6 = (props) => (
        <h6 className="mt-2 mb-2 text-lg" {...props} />
    );

    const blogParagraph = (props) => (
        <p className="my-2 tracking-normal" {...props} />
    );

    const blogAnchor = (props) => (
        <a
            className="my-2 text-highlight tracking-wide hover:underline"
            {...props}
        />
    );

    const blogBlockquote = (props) => (
        <blockquote
            className="pl-5 pr-2 text-lg italic border-l-4"
            {...props}
        />
    );

    const blogPre = (props) => (
        <pre
            className="shadow-inner text-sm md:text-base rounded-md p-8 my-4 overflow-y-scroll"
            {...props}
        />
    );

    const blogOL = (props) => (
        <ol className="my-2 list-decimal px-8" {...props} />
    );

    const blogUL = (props) => <ul className="my-2 list-disc px-8" {...props} />;

    const blogCode = (props) => <code className="" {...props} />;

    const docsTable = (props) => (
        <table
            className="w-full bottom-1 border-grey border-solid mt-8"
            {...props}
        />
    );

    const docsTableHeader = (props) => (
        <th
            className="text-lg md:text-xl lg:text-2xl border border-grey border-solid"
            {...props}
        />
    );

    const docsTableRow = (props) => (
        <tr className="border border-white border-solid" {...props} />
    );

    const docsTableDetail = (props) => (
        <td
            className="border border-grey border-solid text-xs md:text-sm h-10 md:h-8 lg:h-8"
            {...props}
        />
    );

    const docsHr = (props) => <hr className="my-6" {...props} />;

    const components = {
        h1: blogHeader1,
        h2: blogHeader2,
        h3: blogHeader3,
        h4: blogHeader4,
        h5: blogHeader5,
        h6: blogHeader6,
        p: blogParagraph,
        a: blogAnchor,
        blockquote: blogBlockquote,
        pre: blogPre,
        ol: blogOL,
        ul: blogUL,
        code: blogCode,
        th: docsTableHeader,
        table: docsTable,
        tr: docsTableRow,
        td: docsTableDetail,
        hr: docsHr,
    };

    return (
        <BaseLayout>
            <SEO title={title} description={description} />
            <div className="grid grid-cols-12 w-full mx-auto mt-10 lg:mt-20 mb-20 lg:mb-40 gap-6">
                <div className="max-w-[200px] hidden sm:block md:col-span-3 lg:col-span-3 w-full">
                    <DocsSidebar />
                </div>
                <div className="text-left max-w-5xl lg:col-start-4 col-span-12 md:col-span-9 lg:col-span-7">
                    <PrimaryHeader headerText={title} />
                    <MDXProvider components={components}>
                        <MDXRenderer>{body}</MDXRenderer>
                    </MDXProvider>
                </div>
            </div>
        </BaseLayout>
    );
}

export const query = graphql`
    query docsTemplateQuery($id: String!) {
        mdx(id: { eq: $id }) {
            body
            frontmatter {
                title
                description
            }
        }
    }
`;
