import React from 'react';
import { Link } from 'gatsby';
import { docsNavigationTree } from '../../content/docs/config/navigation';

export default function DocsSidebar() {
    return (
        <div className="text-white text-sm bg-grey relative left-0 top-0 w-full p-4 hidden md:block">
            <h3 className="text-lg">Fetch Docs</h3>
            <hr className="mt-2 opacity-50"></hr>
            <div className="-translate-x-2">
                <NavList navItems={docsNavigationTree} />
            </div>
        </div>
    );
}

function NavList({ navItems }) {
    return (
        <div>
            {navItems.map((navItem, index) => (
                <ul key={index}>
                    <NavItem label={navItem.label} slug={navItem.slug} />
                    {navItem.subLinks?.length > 0 && (
                        <div className="translate-x-4">
                            <NavList navItems={navItem.subLinks} />
                        </div>
                    )}
                </ul>
            ))}
        </div>
    );
}

function NavItem({ label, slug, hasSubNav }) {
    return (
        <div className="m-2">
            {slug ? (
                <Link className="" to={slug}>
                    <span className="text-sm hover:text-highlight transition ease-linear ">
                        {label}
                    </span>
                </Link>
            ) : (
                <div>
                    <span className="text-sm transition ease-linear ">
                        {label}
                    </span>
                </div>
            )}
        </div>
    );
}
